<script setup>
    import { ref } from 'vue'
    import { useRouter } from 'vue-router'

    const router = useRouter();

    const pageError = ref(null);
    const working = ref(false);
    const loading = ref(false);
    const searchResult = ref(null);

    const email = ref(null);
    const confirmationNumber = ref(null);

    async function search() {
        searchResult.value = null;
        working.value = true;
        pageError.value = false;

        fetch('/api/Itinerary/Search', {
            method: 'POST',
            credentials: 'include',
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                tenantId: 'TheMole',
                email: email.value,
                confirmationNumber: confirmationNumber.value
            })
        })
        .then(response => {
            if (response.ok) {
                return response.json();
            }
            return Promise.reject(response);
        })
        .then(data => searchResult.value = data)
        .catch(error => {
            console.log(error);
            pageError.value = "No reservations matching your search could be found, please try again.";
        })
        .finally(() => { working.value = false; });
    }

    function load(confirmationNumber, contactId, lastName) {
        if (!loading.value) {
            loading.value = true;

            fetch('/api/Authenticate/GetToken', {
                method: 'POST',
                credentials: 'include',
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    useCookie: true,
                    tenantId: 'TheMole',
                    contactId: contactId,
                    lastName: lastName,
                    confirmationNumber: confirmationNumber
                })
            })
                .then(response => {
                    if (response.ok) {
                        return response.json();
                    }
                    return Promise.reject(response);
                })
                .then(() => {
                    router.push('/');
                })
                .catch(error => {
                    console.log(error);
                    pageError.value = "There was a problem with your login, please try again.";
                })
                .finally(() => { loading.value = false; });
        }
    }
</script>

<template>
    <div class="container">
        <div class="row">
            <div class="col-12 py-auto text-center">
                <h1>Reservations</h1>
                <div class="search" :class="{ working: working }">
                    <form @submit.prevent="search">
                        <div class="input-field">
                            <input type="email" v-model="email" placeholder="Email" />
                        </div>
                        <div class="input-field">
                            <input type="text" v-model="confirmationNumber" placeholder="Booking Confirmation Number" />
                        </div>
                        <div class="input-field">
                            <button v-bind:disabled="working" type="submit" class="btn">Search</button>
                        </div>
                    </form>
                </div>
                <p class="working" v-show="working">Retrieving reservations...</p>
                <p class="error" v-show="pageError">{{ pageError }}</p>
                <table v-if="searchResult" :class="{ loading: loading }">
                    <tr>
                        <th>Name</th>
                        <th>Adults</th>
                        <th>Booking Confirmation</th>
                        <th>Start</th>
                        <th>End</th>
                    </tr>
                    <tr v-for="reservation of searchResult.reservations" v-bind:key="reservation.id" @click="load(reservation.id, reservation.contactId, searchResult.lastName)">
                        <td>{{searchResult.firstName}} {{searchResult.lastName}}</td>
                        <td>{{reservation.adults}}</td>
                        <td>{{reservation.id}}</td>
                        <td>{{new Date(reservation.start).toLocaleDateString('en-gb')}}</td>
                        <td>{{new Date(reservation.end).toLocaleDateString('en-gb')}}</td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</template>

<style>
    .search {
        margin-bottom: 30px;
    }
    .search form {
        display: flex;
        gap: 20px;
    }
    .search form .input-field {
        flex-grow: 1;
    }
    .search form .input-field button {
        width: 100%;
    }
        .search form .input-field button:disabled {
            background: #e4e2d4;
        }

    table {
        width: 100%;
        position: relative;
    }
        table th, table td {
            padding: 4px;
            background: #fff;
        }
        table th {
            background: #bcc1b2
        }

    table:not(.loading) tr:hover td {
        background: #d2a14b;
        color: #fff;
        cursor: pointer;
    }

    table.loading tr {
        opacity: 0.4;
    }
        table.loading:after {
            content: "";
            display: block;
            width: 30px;
            height: 30px;
            border: 4px solid #d2a14b;
            border-bottom-color: transparent;
            border-radius: 50%;
            box-sizing: border-box;
            -webkit-animation: rotation 1s linear infinite;
            animation: rotation 1s linear infinite;
            margin: 20px auto;
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
        }
</style>