<template>
    <div>
        <admin-login-form />
    </div>
</template>

<script>import AdminLoginForm from '../../components/AdminLoginForm.vue'

export default {
  name: 'AdminLoginView',
  components: {
    AdminLoginForm
  }
}</script>