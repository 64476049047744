import { createApp } from 'vue';
import { createRouter, createWebHistory } from 'vue-router';

import App from './App.vue';
import LoginView from "./views/Login.vue";
import ItineraryView from "./views/Itinerary.vue";
import AdminLoginView from "./views/admin/AdminLogin.vue";
import ReservationsView from "./views/admin/ReservationsSearch.vue";

function getCookie(name) {
    var cookieArr = document.cookie.split(";");

    for (var i = 0; i < cookieArr.length; i++) {
        var cookiePair = cookieArr[i].split("=");
        if (name == cookiePair[0].trim()) {
            return decodeURIComponent(cookiePair[1]);
        }
    }
    return null;
}

const routes = [
    {
        path: "/",
        name: "Itinerary",
        component: ItineraryView,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/login",
        name: "Login",
        component: LoginView
    },
    {
        path: "/admin",
        name: "Admin",
        component: AdminLoginView
    },
    {
        path: "/reservations",
        name: "Reservations",
        component: ReservationsView,
        meta: {
            requiresAuth: true,
            requiresAdmin: true
        }
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach(async (to) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        var auth = getCookie("X-Authenticated");

        if (!auth || to.matched.some(record => record.meta.requiresAdmin) && auth != '2') {
            return { name: to.matched.some(record => record.meta.requiresAdmin) ? 'Admin' : 'Login' }
        }
    }
});

const eeApp = createApp(App)
eeApp.use(router)
eeApp.mount('#app')