<template>
    <reservation-itinerary />
</template>

<script>import ReservationItinerary from '../components/ReservationItinerary.vue'

export default {
  name: 'ItineraryView',
  components: {
    ReservationItinerary
  }
}</script>