<script setup>
    import { ref } from 'vue'
    import { useRouter } from 'vue-router'

    const router = useRouter()

    const username = ref(null);
    const password = ref(null);

    const loginError = ref(null);
    const working = ref(false);

    async function login() {
        working.value = true;
        loginError.value = false;

        fetch('/api/Authenticate/GetAdminToken', {
            method: 'POST',
            credentials: 'include',
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                useCookie: true,
                tenantId: 'TheMole',
                username: username.value,
                password: password.value
            })
        })
        .then(response => {
            if (response.ok) {
                return response.json();
            }
            return Promise.reject(response);
        })
        .then(() => {
            router.push('/reservations');
        })
        .catch(error => {
            console.log(error);
            loginError.value = "There was a problem with your login, please try again.";
        })
        .finally(() => { working.value = false; });
    }
</script>

<template>
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="login-box text-center">
                    <div class="login-box-inner" :class="{ working: working }">
                        <h1>Admin Login</h1>
                        <p>Enter your login credentials to continue.</p>
                        <form @submit.prevent="login">
                            <div class="input-field">
                                <input type="text" v-model="username" placeholder="Username" required />
                            </div>
                            <div class="input-field">
                                <input type="password" v-model="password" placeholder="Password" required />
                            </div>
                            <p class="error" v-show="loginError">{{ loginError }}</p>
                            <div class="input-field">
                                <button v-bind:disabled="working" type="submit" class="btn" style="width:95%">Login</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
    .login-box {
        color: #342f24;
        background: #bcc1b2;
        padding: 40px 30px;
        max-width: 900px;
        margin: 0 auto;
    }
    .login-box-inner {
        max-width: 400px;
        margin: 0 auto;
        position: relative;
    }
    .login-box-inner.working:after {
        content: '';
        width: 30px;
        height: 30px;
        border: 4px solid #d2a14b;
        border-bottom-color: transparent;
        border-radius: 50%;
        display: inline-block;
        box-sizing: border-box;
        animation: rotation 1s linear infinite;
        position: absolute;
        bottom: 10px;
        left: 0;
        right: 0;
        margin: 0 auto;
    }
    .login-box h1 {
        font-weight: 400;
        font-size: 38px;
        font-family: foxglove,arial,sans-serif;
    }
    .input-field {
        margin-top: 10px;
    }
    .input-field input {
        padding: 10px;
        border: 2px solid #88a156;
        background: #fff;
        width: 95%;
    }
    .error {
        color: #ae2b2b;
        margin-top: 10px;
    }
    @keyframes rotation {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }
</style>