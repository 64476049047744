<script setup>
    function getCookie(name) {
        var cookieArr = document.cookie.split(";");

        for (var i = 0; i < cookieArr.length; i++) {
            var cookiePair = cookieArr[i].split("=");
            if (name == cookiePair[0].trim()) {
                return decodeURIComponent(cookiePair[1]);
            }
        }
        return null;
    }

    const auth = getCookie("X-Authenticated");
</script>

<template>
    <nav>
        <router-link to="/reservations" v-if="auth == '2'" class="btn-secondary">Admin</router-link>
    </nav>
    <router-view />
</template>

<style>
    nav { position:absolute; top:0; right: 0; }
</style>